import PropTypes from 'prop-types';

export const Logo = (props) => {
  const { variant, src } = props;

  const color = variant === 'light' ? '#ffffff' : '#1D262D';

  return (
    <img
      src={src}
      alt={color}
    />
  );
};

Logo.defaultProps = {
  variant: 'dark'
};

Logo.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
  src: PropTypes.string.isRequired
};
