import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {}
  },
  fi: {
    translation: {
      'Add new price': 'Lisää uusi hinta',
      Add: 'Lisää',
      'Are you sure you want to delete this price?': 'Haluatko varmasti poistaa tämän hinnan?',
      Cancel: 'Peruuta',
      'Complete the registration': 'Suorita rekisteröinti loppuun',
      'Code is mandatory': 'Koodi on pakollinen',
      Confirm: 'Vahvista',
      'Created at': 'Luotu klo',
      'Created by': 'Luonut',
      Copy: 'Kopioi',
      Delete: 'Poista',
      'Delete price': 'Poista hinta',
      'Email address': 'Sähköpostiosoite',
      'Enter the email address you used when you joined and we will send you instructions to reset your password.': 'Anna sähköpostiosoite, jota käytit liittyessäsi, niin lähetämme sinulle ohjeet salasanan vaihtamiseen.',
      Edit: 'Muokkaa',
      'Forgot password': 'Unohtuiko salasana',
      'If this email address was used to create an account, instructions to reset your password will be sent to you.': 'Jos tätä sähköpostiosoitetta käytettiin tilin luomiseen, sinulle lähetetään ohjeet salasanan vaihtamiseen.',
      'Incorrect period. Should be in format of YYYY-MM': 'Väärä kausi. Pitäisi olla muodossa VVVV-KK',
      'is mandatory': 'on pakollinen',
      Search: 'Haku',
      'Updated at': 'Päivitetty klo',
      'Updated by': 'Päivittänyt',
      'Update price': 'Päivitä hinta',
      Customers: 'Asiakkaat',
      'Customer ID': 'ID-numero',
      'Customer name': 'Pihan nimi',
      'Language changed': 'Kieli vaihtunut',
      Login: 'Kirjaudu sisään',
      'Log out': 'Kirjaudu ulos',
      Mandatory: 'Pakollinen',
      'Must be a valid email': 'Täytyy olla voimassa oleva sähköpostiosoite',
      'Must be at least 8 characters, containing lowercase, uppercase, and numbers': 'On oltava vähintään 8 merkkiä, sisältäen pieniä kirjaimia, isoja kirjaimia ja numeroita',
      'New password': 'Uusi salasana',
      Page: 'Sivu',
      Password: 'Salasana',
      'Password confirmation': 'Salasanan vahvistus',
      'Passwords must match': 'Salasanojen täytyy täsmätä',
      'Password recovery': 'Salasanan palautus',
      Period: 'Kausi',
      'Period start': 'Kauden alkamispäivä',
      Price: 'Hinta',
      Prices: 'Hinnat',
      'Price list': 'Hinnasto',
      'Product code': 'Tuotekoodi',
      Reset: 'Nollaa',
      'Reset password': 'Nollaa salasana',
      'Send verification email': 'Lähetä vahvistusviesti',
      Submit: 'Lähetä',
      'There are not objects here yet.': 'Täällä ei ole vielä tietoa.',
      Update: 'Päivitä',
      'Verification code': 'Vahvistuskoodi',
      'You must choose new password to complete registration for your account': 'Sinun on valittava uusi salasana viimeistelläksesi tilisi rekisteröinnin',
      'YYYY-MM': 'VVVV-KK'
    }
  }
};

export const initializeI18n = (lng) => {
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    });
};
